export default {
  pageTitle: 'Value Chain Map',
  createNewValue: 'Add a new value chain map',
  searchByName: 'Search by name',
  filterByStatus: 'Filter by status',
  thereAreNoValueChanMaps: 'There are no Value Chain Maps yet',
  name: 'Name',
  createdDate: 'Created',
  lastModifiedAt: 'Last modified',
  status: 'Status',
  action: 'Actions',
};
