const VALUE_CHAIN_HEAD_CELLS = [
  {
    id: 'name',
    label: 'valueChainMap.name',
    withSort: true,
  },
  {
    id: 'createdAt',
    label: 'valueChainMap.createdDate',
    withSort: true,
  },
  {
    id: 'lastModifiedAt',
    label: 'valueChainMap.createdDate',
    withSort: true,
  },
  {
    id: 'status',
    label: 'valueChainMap.status',
    withSort: false,
  },
  {
    id: 'actions',
    label: 'valueChainMap.actions',
    withSort: false,
  },
];

export default VALUE_CHAIN_HEAD_CELLS;
