import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Button from 'components/Button/Button';
import Title from 'components/Title/Title';
import { AppRoutes } from 'constants/AppRoutes';

import type IValueChainMapHeading from '../../interfaces/IValueChainMapHeading';

import styles from './scss/ValueChainMapTableHeading.module.scss';

function ValueChainMapHeading({ isEmpty }: IValueChainMapHeading) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onHandleClick = () => {
    navigate(AppRoutes.valueChainMap);
  };

  return (
    <div className={styles.root}>
      <Title className={styles.title} text={t('valueChainMap.pageTitle')} />
      { !isEmpty && (
      <Button
        onClick={onHandleClick}
        startIcon={<PlusIcon />}
        title={t('valueChainMap.createNewValue')}
      />
      )}
    </div>
  );
}

export default ValueChainMapHeading;
