import { useState } from 'react';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Avatar from 'components/Avatar/Avatar';
import Badge from 'components/Badge/Badge';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import IconButton from 'components/IconButton/IconButton';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import AppRoutes from 'constants/AppRoutes';
import { COMPANY_STATUSES } from 'constants/company';
import { EXTEND_DATE_FORMAT } from 'constants/general';
import { COMPANY_STATUS } from 'constants/interfaces';
import dayjs from 'dayjs';

import ValueChainMapTableActions from '../ValueChainMapTableActions/ValueChainMapTableActions';

import type IValueChainMapTableRow from '../../interfaces/IValueChainRow';

import styles from './scss/ValueChainMapTableRow.module.scss';

function ValueChainMapTableRow({ company }: IValueChainMapTableRow) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onHandleEdit = () => {
    navigate(generatePath(AppRoutes.companyInfo, { clientId: company.id }));
  };

  return (
    <TableRow>
      <TableCell className={styles.nameCell}>
        <Link
          className={styles.client}
          to={generatePath(AppRoutes.clientProfile, { clientId: company.id })}
        >
          <Avatar
            alt={company.name}
            src={company.uiConfiguration?.companyLogo}
          />
          <p className={styles.clientName}>
            {company.name}
          </p>
        </Link>
      </TableCell>
      <TableCell>
        {dayjs(company.createdAt).format(EXTEND_DATE_FORMAT)}
      </TableCell>
      <TableCell>
        <div className={styles.status}>
          <Badge
            textColor={COMPANY_STATUSES[company.status].textColor}
            color={COMPANY_STATUSES[company.status].color}
            status={COMPANY_STATUSES[company.status].label}
          />
          {company.status === COMPANY_STATUS.deactivated ? (
            <IconButton
              size="small"
              className={styles.info}
              onClick={() => setIsModalOpen(true)}
              icon={<InfoIcon />}
              isWarning
            />
          ) : null}
        </div>

        <DeactivationInfoModal
          open={isModalOpen}
          deactivationInfo={company.latestDeactivation}
          onClose={() => setIsModalOpen(false)}
        />
      </TableCell>
      <TableCell>
        {company.companyAdminUsers.length ? company.companyAdminUsers.map((admin) => (
          <p key={`companyAdmin_${admin.id}`} className={styles.adminNames}>{`${admin.firstName} ${admin.lastName}`}</p>
        )) : '-'}
      </TableCell>
      <TableCell>
        <ValueChainMapTableActions
          companyId={company.id}
          status={company.status}
          onHandleEdit={onHandleEdit}
        />
      </TableCell>
    </TableRow>
  );
}

export default ValueChainMapTableRow;
