import ValueChainMapFilters from './components/ValueChainMapFilters/ValueChainMapTableFilters';
import ValueChainMapHeading from './components/ValueChainMapHeading/ValueChainMapTableHeading';
import ValueChainMapTable from './components/ValueChainMapTable/ValueChainMapTable';

import type IValueChainView from './interfaces/IValueChainView';

import styles from './scss/ClientsView.module.scss';

function ValueChainMapView({
  control, valueChainList, onSearch, onFilterChange, onSortChange,
  onReadMoreClients, hasNext, isWithSearch, isPageLoading,
}: IValueChainView) {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <ValueChainMapHeading isEmpty={!valueChainList.length} />
        {
          valueChainList.length !== 0 && (
            <ValueChainMapFilters
              control={control}
              onSearch={onSearch}
              onFilterChange={onFilterChange}
            />
          )
        }
        <ValueChainMapTable
          control={control}
          onSortChange={onSortChange}
          companies={valueChainList}
          onReadMoreClients={onReadMoreClients}
          hasNext={hasNext}
          isWithSearch={isWithSearch}
          isPageLoading={isPageLoading}
        />
      </div>
    </div>
  );
}

export default ValueChainMapView;
