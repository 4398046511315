import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import EmptyList from 'components/EmptyList/EmptyList';
import Loader from 'components/Loader/Loader';
import Panel from 'components/Panel/Panel';
import Table from 'components/Table/Table';
import TableBody from 'components/Table/TableBody';
import AppRoutes from 'constants/AppRoutes';

import ValueChainMapTableHead from '../ValueChainMapHead/ValueChainMapTableHead';
import ValueChainMapTableRow from '../ValueChainMapRow/ValueChainMapTableRow';

import type IClientsTable from '../../interfaces/IValueChainTable';

import styles from './scss/ValueChainMapTable.module.scss';

function ValueChainMapTable({
  control,
  onSortChange,
  companies,
  onReadMoreClients,
  hasNext,
  isWithSearch,
  isPageLoading,
}: IClientsTable) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onHandleClick = () => {
    navigate(AppRoutes.valueChainMap);
  };

  const emptyListText = isWithSearch ? (
    <Trans
      t={t}
      i18nKey="common.noResultsWithFilters"
      components={{
        br: <br />,
      }}
    />
  ) : t('valueChainMap.thereAreNoValueChanMaps');

  return (
    <Panel className={styles.root}>
      <Loader isPageLoading={isPageLoading} />

      {companies.length ? (
        <InfiniteScroll
          className={styles.infiniteScroll}
          next={onReadMoreClients}
          dataLength={companies.length}
          loader={false}
          hasMore={hasNext}
        >
          <Table>
            <ValueChainMapTableHead
              onSortChange={onSortChange}
              control={control}
            />
            <TableBody>
              {companies.map((company) => (
                <ValueChainMapTableRow
                  company={company}
                  key={window.crypto.randomUUID()}
                />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      ) : !isPageLoading && (
        <EmptyList text={emptyListText}>
          {!isWithSearch ? (
            <Button
              title={t('valueChainMap.createNewValue')}
              onClick={onHandleClick}
            />
          ) : null}
        </EmptyList>
      )}
    </Panel>
  );
}

export default ValueChainMapTable;
