export default {
  valueChainAssessment: 'Value Chain Map',
  materialityAssessment: 'Materiality Assessment',
  settings: 'Settings',

  'settings/user-management': 'User Management',
  'settings/user-management-description': 'Create and manage users',

  'settings/data-sets-management': 'Data Sets Management',
  'settings/data-sets-management-description': 'Upload lists of datasets',

  'settings/ui-configuration': 'UI Configuration',
  'settings/ui-configuration-description': 'Configure the company UI',

  'settings/multi-lingual': 'Multi-lingual',

  'materiality-assessment/entity-editing': 'Entity Editing',
  'materiality-assessment/entity-linking': 'Entity Linking',
  'value-chain-assessment/entity-editing': 'Entity Editing',
  'value-chain-assessment/entity-linking': 'Entity Linking',
};
