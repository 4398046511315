import { useTranslation } from 'react-i18next';
import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import IconButton from 'components/IconButton/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import { COMPANY_ACTION_STATUSES } from 'constants/company';
import useCompanyStatusChange from 'hooks/useCompanyStatusChange';

import type IValueChainMapTableActions from '../../interfaces/IValueChainActions';

import styles from './scss/ValueChainMapTableActions.module.scss';

function ValueChainMapTableActions({
  companyId, status, onHandleEdit,
}: IValueChainMapTableActions) {
  const { t } = useTranslation();

  const {
    onToggleDeactivationModal,
    onCompanyDeactivate,
    isDeactivationModalOpen,
    onChangeCompanyStatus,
  } = useCompanyStatusChange({
    companyId, status,
  });

  return (
    <>
      <div className={styles.wrapper}>
        <Tooltip hint={t('clients.editInformation')}>
          <IconButton
            icon={<PenIcon />}
            onClick={onHandleEdit}
          />
        </Tooltip>
        <Tooltip hint={COMPANY_ACTION_STATUSES[status].hintText}>
          <IconButton
            onClick={onChangeCompanyStatus}
            icon={COMPANY_ACTION_STATUSES[status].actionIcon}
          />
        </Tooltip>
      </div>
      <DeactivationModal
        onSubmit={onCompanyDeactivate}
        text={t('common.clientActivity')}
        onReject={onToggleDeactivationModal}
        open={isDeactivationModalOpen}
      />
    </>
  );
}

export default ValueChainMapTableActions;
