import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ORDER_KEY, ORDER_TYPE, SEARCH, SORT,
} from 'constants/formFields';
import { ORDERS } from 'constants/interfaces';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { companiesListSchema } from 'utils/validators';

import ValueChainMapView from './ValueChainMapView';

import type { ISearchInputHandler } from 'components/TextField/interfaces/ITextField';
import type { TCompaniesListSchema } from 'utils/validators';

export default function ValueChainMap() {
  const {
    control,
    reset,
  } = useForm<TCompaniesListSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(companiesListSchema),
    defaultValues: {
      [SEARCH]: '',
      [SORT]: {
        [ORDER_KEY]: '',
        [ORDER_TYPE]: ORDERS.asc,
      },
    },
  });

  const debouncedSearch = useDebouncedCallback(() => {});

  const onSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const onReadMoreClients = () => {};

  const onFilterChange = () => {};

  const onSortChange = () => {};

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <ValueChainMapView
      control={control}
      valueChainList={[]}
      onReadMoreClients={onReadMoreClients}
      hasNext={false}
      onSearch={onSearchChange}
      onFilterChange={onFilterChange}
      onSortChange={onSortChange}
      isWithSearch={false}
      isPageLoading={false}
    />
  );
}
